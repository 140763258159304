import { differenceWith, intersection } from "lodash";
import React from "react";
import { ItemValue, Question, QuestionFactory } from "survey-core";
import { ReactElementFactory, SurveyQuestionCheckboxItem } from "survey-react-ui";

export class CheckboxItemExtension extends SurveyQuestionCheckboxItem {
    constructor(props: any) {
        super(props);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange = (event: any) => {
        const survey = this.props.survey;
        const comment = event.target.value;

        this.item.comment = comment;

        const hiddenQuestionName = buildAcceptTextQuestionName(this.question, this.item);
        let hiddenQuestion = survey.getQuestionByName(hiddenQuestionName);

        if (!hiddenQuestion) {
            hiddenQuestion = QuestionFactory.Instance.createQuestion('text', hiddenQuestionName);
            hiddenQuestion.visible = false;  // Make it hidden
        }

        survey.setValue(hiddenQuestionName, comment, false, true);
        this.question.validate();
    }

    renderElement() {
        if (this.item.acceptText === true && this.item.selected) {
            return (
                <div>
                    {super.renderElement()}
                    <div className="input-box-container">
                        <input
                            className="sd-input sd-text"
                            type="text"
                            placeholder="Enter text..."
                            defaultValue={this.item?.comment || ''}
                            name={this.item.value + '-comment'}
                            onChange={this.handleInputChange}
                        />
                    </div>
                </div>
            )
        }
        return super.renderElement();
    }
}

export const registerCheckboxItemExtension = (survey: any) => {
    ReactElementFactory.Instance.registerElement("checkbox-item-ext", (props) => {
        // @ts-ignore
        return React.createElement(CheckboxItemExtension, { ...props, survey: survey });
    });
};

export const buildAcceptTextQuestionName = (question: Question, choice: any) => {
    return question.name + '-' + choice.value + '-Comment';
}

export const isItemExtensionQuestion = (question: Question): boolean => {
  return question?.itemComponent?.includes("-item-ext");
}

export function validateTextEntry(_: any, options: any, survey: any) {
  const question = options.question;
  const isAcceptTextRequired = question?.jsonObj?.isAcceptTextRequired;

  if (isItemExtensionQuestion(question) && isAcceptTextRequired) {
    const answers = Array.isArray(question.value) ? question.value : [question.value];
    const choices = question.choices;

    const hasEmptyTextEntry = answers.some((answer: string) => {
      const choice = choices.find((choice: ItemValue) => choice.value === answer);

      if (!choice) {
        return;
      }

      const commentQuestionName = buildAcceptTextQuestionName(question, choice);
      const commentQuestionValue = survey.data[commentQuestionName];

      return choice.acceptText && !commentQuestionValue;
    });

    if (hasEmptyTextEntry) {
      options.error = "Text entry required";
    }
  }
}
export const onValueChangingCheckbox = (survey: any, options: any) => {
  const question = options.question?.jsonObj;
  let oldValue = options.oldValue;
  let value = options.value;

  if (question?.type === "checkbox") {
    const exclusiveChoices = options.question.choices.filter((c: any) => c.jsonObj.exclusive).map((c: any) => c.jsonObj.value);
    const common = intersection(differenceWith(value, oldValue), exclusiveChoices);
    let result: any[] = [];

    if (common?.length) {
      result = common;
    } else {
      result = value.filter((c: any) => !exclusiveChoices.includes(c));
    }

    options.value = result;
  }
};
export const onValueChangingMultiSelect = (survey: any, options: any) => {
  const question = options.question?.jsonObj;
  if (question?.type === "tagbox" && Array.isArray(options.value)) {
    let value = options.value;
    let result = [...value]
    const isNoneExist = value.some((i: string) => i === 'none');
    if (question.showSelectAllItem && value.length === question.choices.length && isNoneExist) {
      if (isNoneExist) {
        result = result.filter((i: string) => i !== 'none');
      }
    }
    if (isNoneExist && value.length > 1) {
      result = result.filter((i: string) => i !== 'none');
    }
    options.value = result;
  }
};

export const onValueChangingMultiSelectComment = (survey: any, options: any) => {
  const question = options.question?.jsonObj;
  if (question?.type === "tagbox" && options?.name?.includes('-Comment')) {
    let value = options.value;
    if(value) {
      survey.setValue(`${question.name}-Comment-custom`, value);
    }
  }
};
export const onValueChangingSelect = (survey: any, options: any) => {
  const question = options.question?.jsonObj;
  if (question?.type === "dropdown" && options?.name?.includes('-Comment')) {
    let value = options.value;
    if(value) {
      survey.setValue(`${question.name}-Comment-custom`, value);
    }
  }
};

import React from "react";
import { PropertyGridViewModel, SearchManager } from "survey-creator-core";
import { Base, SurveyModel, settings } from "survey-core";
import { Survey, SurveyElementBase, ReactQuestionFactory, SurveyQuestionButtonGroup, ReactElementFactory, SvgIcon, SurveyActionBar } from "survey-react-ui";
import { SearchComponent } from "./Search";

interface IPropertyGridComponentProps {
  model: PropertyGridViewModel;
}

export class PropertyGridComponent extends SurveyElementBase<IPropertyGridComponentProps, any> {

  get model(): PropertyGridViewModel {
    return this.props.model;
  }

  protected getStateElement(): Base {
    return this.model;
  }

  public canRender(): boolean {
    if (!this.model) return false;
    return super.canRender();
  }

  protected isDisableTab(tabName): boolean {
    const currentEl = this.currentElementName();
    if(currentEl === tabName) return false;
    let result = false;
    if(currentEl === "Survey") {
      if(tabName === "page" || tabName === "question") result = true;
    }
    if(currentEl === "page") {
      if(tabName === "Survey") result = false;
      if(tabName === "question") result = true;
    }
    if(currentEl === "question") {
      if(tabName === "page" || tabName === "Survey") return false;
    }
    return result;
  }

  protected currentElementName(): string {
    let questions = this.props.model?.survey?.currentPage?.questions || [];
    questions = questions.map(i => i.title);
    const isQuestionInclude = questions.some(i => i.includes("Question title")) && this?.state?.selectedElementName !== "Survey";
    const isPageInclude = questions.some(i => i.toLowerCase().includes("Section title")) && this?.state?.selectedElementName !== "Survey";
    if(!this?.state?.selectedElementName) return "Survey";
    if(this?.state?.selectedElementName.includes("question") || isQuestionInclude) return "question";
    if(this?.state?.selectedElementName.includes("page") || isPageInclude) return "page";
    return "Survey";
  }

  protected handleChangeTab(tabName): void {
    //@ts-ignore
    if(this.isDisableTab(tabName)) return;
    if(tabName === this.currentElementName()) return;
    if(tabName === "page") {
      //@ts-ignore
      if(this.model.creator.survey.pages[0]) {
        //@ts-ignore
        const page = this.model.creator.survey.currentPage || this.model.creator.survey.pages[0];
        if(!page) return;
        //@ts-ignore
        this.model.creator.selectElement(page, "page", true);
      }
    }
    if(tabName === "question") {
      //@ts-ignore
      const page = this.model.creator.survey.currentPage || this.model.creator.survey.pages[0];
      if(!page) return;
      //@ts-ignore
      this.model.creator.selectElement(page.questions[0], null, true);

    }
    if(tabName === "Survey") {
      // @ts-ignore
      this.model.creator.selectElement(this.model.creator.survey, null, true);
    }
  }

  protected getStyleForTab(tabName): object {
    let styles = {};
    const styleContainerTab = { flex: 1, display: "flex", alignItems: "center", justifyContent: "center", padding: 10, fontSize: 16, cursor: "pointer" };
    const styleContainerSelectedTab = { flex: 1, display: "flex", alignItems: "center", justifyContent: "center", borderBottom: "2px solid #004036", padding: 10, fontSize: 16, cursor: "pointer" };
    styles = this.currentElementName() === tabName ? styleContainerSelectedTab : styleContainerTab;
    if(this.isDisableTab(tabName)) {
      styles = { ...styles, color: "grey", cursor: "not-allowed" };
    }
    return styles;
  }

  renderElement() {
    const rootClassName = this.model.searchEnabled ? "spg-container spg-container_search" : "spg-container";
    return (
      <div className={rootClassName}>
        <div style={{ display: "flex", justifyContent: "space-around", width: "100%", borderBottom: "1px solid var(--Greyscale-Grey-2, #CBCCCE)" }} >
          <div onClick={() => this.handleChangeTab("Survey")} style={this.getStyleForTab("Survey")}>
            <SvgIcon size={16} iconName={"icon-survey-icon"} title='survey-icon'></SvgIcon>
            <span>Survey</span>
          </div>
          <div onClick={() => this.handleChangeTab("page")} style={this.getStyleForTab("page")}>
            <SvgIcon size={16} iconName={"icon-section-icon"} title='section-icon'></SvgIcon>
            <span>Section</span>
          </div>
          <div onClick={() => this.handleChangeTab("question")} style={this.getStyleForTab("question")} >
            <SvgIcon size={16} iconName={"icon-question-icon"} title='question-icon'></SvgIcon>
            <span>Question</span>
          </div>
        </div>
        {/* <SearchComponent model={this.model.searchManager}></SearchComponent> */}
        <Survey model={this.model.survey}></Survey>
      </div>
    );
  }
}

ReactQuestionFactory.Instance.registerQuestion("buttongroup", (props) => {
  return React.createElement(SurveyQuestionButtonGroup, props);
});

export default PropertyGridComponent;

ReactElementFactory.Instance.registerElement("svc-property-grid", (props) => {
  return React.createElement(PropertyGridComponent, props);
});

import axios from "axios";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { TAKER_GATEWAY_URL } from '../../constants/env';
dayjs.extend(utc);
export class SurveyTakerService {
    
      async getSurveyToken(token: string, orgId: string, params: any={}) {
        const body = {
          "surveyToken": token,
          "orgId": orgId,
          "redirect": false,
          "metaData": {},
          "type": "anonymous",
          "currentDateTime": dayjs().format('YYYY-MM-DD HH:mm:ss.SSS')
        }
        return axios.post(`${TAKER_GATEWAY_URL}/api/get-survey-token`, body, {params});
      }

      async getSurveyTokenForIndividualLinks(token: string, params: any={}) {
        const body = {
          "surveyToken": token,
          "redirect": false,
          "metaData": {},
          "type": "individual",
          "currentDateTime": dayjs().format('YYYY-MM-DD HH:mm:ss.SSS')
        }
        return axios.post(`${TAKER_GATEWAY_URL}/api/get-survey-token`, body, {params});
      }
    
      async fetchSurvey(token: string) {
        return axios({
          url: `${TAKER_GATEWAY_URL}/api/fetch-survey`,
          method: 'get',
          headers: {
            'Content-Type': 'application/json',
            'X-Authorization-Survey': `Bearer ${token}`
          }
        });
      }
    
      async submitSurvey(token: string, body: any) {
        return axios.post(`${TAKER_GATEWAY_URL}/api/submit-response`, body, {
          headers: {
            'Content-Type': 'application/json',
            'X-Authorization-Survey': `Bearer ${token}`
          }
        });
      }
      
      async validationTokenCaptcha(token: string, body: any) {
        return axios.post(`${TAKER_GATEWAY_URL}/api/validate-captcha`, body, {
          headers: {
            'Content-Type': 'application/json',
            'X-Authorization-Survey': `Bearer ${token}`
          }
        });
      }

      async deleteSurveyResponse(token: string) {
        return axios({
          url: `${TAKER_GATEWAY_URL}/api/delete-survey`,
          method: 'get',
          headers: {
            'Content-Type': 'application/json',
            'X-Authorization-Survey': `Bearer ${token}`
          }
        });
      }
    }
export const surveyTakerService = new SurveyTakerService();
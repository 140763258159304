import { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { PerformanceService } from "hanover-survey-library";
import { VariablesStore } from "./store/variables_store";
import TakeSurveyWrapper from "./components/SurveyTaker/SurveyTakerWrapper";
import { surveyTakerService } from "./components/SurveyTaker/SurveyTaker.service";
import { LOGGER_ENABLED, RECAPTCHA_KEY, RECAPTCHAV3_KEY, REV_ID_SRC_URL, RECAPTCHA_SRC_URL, TAKER_GATEWAY_URL } from "./constants/env";

function App() {
  useEffect(() => {
    console.log(`+++ Hanover Taker. Version: 0.1.4`);
    console.log(`+++ Hanover Survey Library. Version: 1.0.88`);

    window.h = {
      DESIGNER_ENV: {
        LOGGER_ENABLED: LOGGER_ENABLED,
        RECAPTCHA_KEY: RECAPTCHA_KEY,
      },
      TAKER_ENV: {
        TAKER_GATEWAY_URL: TAKER_GATEWAY_URL,
        RECAPTCHAV3_KEY: RECAPTCHAV3_KEY,
        REV_ID_SRC_URL: REV_ID_SRC_URL,
        RECAPTCHA_SRC_URL: RECAPTCHA_SRC_URL,
      },
      performanceService: new PerformanceService(),
      variablesStore: new VariablesStore(),
      validationTokenCaptcha: surveyTakerService.validationTokenCaptcha,
    } as any;
    Object.freeze(window.h);
  }, []);

    return (
      <>
        <Router>
          <Routes>
              <Route path="/ts/ut" element={<TakeSurveyWrapper />}/>
              <Route path="/take-survey/:orgId/:token" element={<TakeSurveyWrapper />}/>
          </Routes>
        </Router>
      </>
    );
}

export default App;

import React, { useState, useEffect, useRef, CSSProperties, MouseEvent } from "react";

const dotMenuContainerStyle: CSSProperties = {
  position: "relative",
  display: "inline-block",
};

const dotMenuButtonStyle: CSSProperties = {
  background: "none",
  border: "none",
  cursor: "pointer",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "16px",
  width: "13px",
  padding: 0,
};

const dotStyle: CSSProperties = {
  backgroundColor: "#6E6E6E",
  borderRadius: "50%",
  height: "4px",
  width: "4px",
};

const dropdownMenuStyle = (isOpen: boolean): CSSProperties => ({
  display: isOpen ? "block" : "none",
  position: "absolute",
  top: "28px",
  left: "-10px",
  background: "white",
  border: "1px solid #ccc",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  borderRadius: "4px",
  overflow: "hidden",
  zIndex: 1000,
});

const dropdownItemStyle: CSSProperties = {
  padding: "8px 16px",
  cursor: "pointer",
};

const dropdownItemHoverStyle: CSSProperties = {
  backgroundColor: "#f0f0f0",
};

// eslint-disable-next-line react/prop-types
const DotMenu = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Close menu on miss click
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    //@ts-ignore
    document.addEventListener("mousedown", handleClickOutside as EventListener);
    return () => {
      //@ts-ignore
      document.removeEventListener("mousedown", handleClickOutside as EventListener);
    };
  }, []);

  const confirmDeleteLogic = () => {
    // eslint-disable-next-line react/prop-types
    props.callbackOnDelete();
  };

  const confirmEditLogic = () => {
    // eslint-disable-next-line react/prop-types
    props.callbackOnEdit();
  };

  return (
    <div style={dotMenuContainerStyle} ref={menuRef}>
      <button style={dotMenuButtonStyle} onClick={toggleMenu}>
        <span style={dotStyle} />
        <span style={dotStyle} />
        <span style={dotStyle} />
      </button>
      <div style={dropdownMenuStyle(isOpen)}>
        <div
          style={dropdownItemStyle}
          onMouseOver={(e) => (e.currentTarget.style.backgroundColor = dropdownItemHoverStyle.backgroundColor)}
          onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "")}
          onClick={confirmDeleteLogic}
        >
          Delete
        </div>
        <div
          style={dropdownItemStyle}
          onMouseOver={(e) => (e.currentTarget.style.backgroundColor = dropdownItemHoverStyle.backgroundColor)}
          onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "")}
          onClick={confirmEditLogic}
        >
          Edit
        </div>
      </div>
    </div>
  );
};

export default DotMenu;
import { QuestionAdornerViewModel, toggleHovered } from "survey-creator-core";
import React from "react";
import { ReactDragEvent, ReactMouseEvent } from "../events";
import { Base, Question, settings } from "survey-core";
import {
  SurveyActionBar,
  ReactElementFactory,
  SurveyQuestion,
  attachKey2click,
  SvgIcon,
  Popup
} from "survey-react-ui";
import { CreatorModelElement } from "../ModelElement";
import DotMenu from "./Dots";
export interface QuestionAdornerComponentProps {
  element: JSX.Element;
  question: Question;
  componentData: any;
}

export class QuestionAdornerComponent extends CreatorModelElement<
  QuestionAdornerComponentProps,
  any
> {
  private modelValue: QuestionAdornerViewModel;
  protected rootRef: React.RefObject<HTMLDivElement>;

  protected createModel(props: any): void {
    if (this.modelValue) {
      this.modelValue.dispose();
    }
    this.modelValue = this.createQuestionViewModel(props);
  }
  protected createQuestionViewModel(props: any): QuestionAdornerViewModel {
    return new QuestionAdornerViewModel(
      props.componentData,
      props.question,
      null
    );
  }
  protected getUpdatedModelProps(): string[] {
    return ["question", "componentData"];
  }
  public get model(): QuestionAdornerViewModel {
    return this.modelValue;
  }
  protected getStateElement(): Base {
    return this.model;
  }
  protected canRender(): boolean {
    return super.canRender() && !this.model.isDragged;
  }

  renderElement(): JSX.Element {
    const allowInteractions = this.model.element
      .isInteractiveDesignElement;
    const content = this.renderContent(allowInteractions);
    return (
      <div
        ref={this.rootRef}
        data-sv-drop-target-survey-element={this.model.element.name || null}
        className={"svc-question__adorner" + this.model.rootCss()}
        onMouseOut={e => allowInteractions && this.model.hover(e.nativeEvent, e.currentTarget)}
        onMouseOver={e => allowInteractions && this.model.hover(e.nativeEvent, e.currentTarget)}
      >
        {content}
      </div>
    );
  }
  protected disableTabStop() {
    return true;
  }
  protected renderContent(allowInteractions: boolean): JSX.Element {
    var content = this.renderElementContent();
    //if (!allowInteractions) return <>{content}{this.renderFooter()}</>;
    return attachKey2click(
      <div
        className={this.model.css()}
        onClick={(e) => {
          this.model.select(this.model, new ReactMouseEvent(e));
        }}
      >
        {allowInteractions ? this.renderHeader() : null}
        {content}
        {this.renderFooter()}
        {this.renderConditions()}
      </div>,
      undefined, { disableTabStop: this.disableTabStop() });
  }
  protected renderHeader(): JSX.Element {
    return ReactElementFactory.Instance.createElement("svc-question-header", { model: this.model });
  }
  protected renderFooter(): JSX.Element {
    const allowInteractions = this.model.element
      .isInteractiveDesignElement;
    return allowInteractions ? ReactElementFactory.Instance.createElement("svc-question-footer", { className: "svc-question__content-actions", model: this.model }) : null;
  }
  protected renderCarryForwardBanner(): JSX.Element {
    if (!this.model.isBannerShowing) return null;
    return ReactElementFactory.Instance.createElement("svc-question-banner", this.model.createBannerParams());
  }
  protected renderElementContent(): JSX.Element {
    return (
      <>
        {this.props.element}
        {this.renderElementPlaceholder()}
        {this.renderCarryForwardBanner()}
      </>
    );
  }
  renderElementPlaceholder(): JSX.Element {
    if (!this.model.isEmptyElement) {
      return null;
    }
    return (
      <div className="svc-panel__placeholder_frame-wrapper">
        <div className="svc-panel__placeholder_frame">
          <div className="svc-panel__placeholder">
            {this.model.placeholderText}
          </div>
        </div>
      </div>
    );
  }

  callbackOnDelete() {
    if (window.confirm("Are you sure you want to delete this logic?")) {
      this.props.question.visibleIf = "";
    }
  }

  callbackOnEdit() {
    settings.showModal(
      "sv-custom-logic-modal",
      { model: this.props.question, type: "visibleIf", data: this.props.question.visibleIf },
      () => {
        this.props.question.visibleIf = this.props.question.newSchemaCondition;
        delete this.props.question.newSchemaCondition;
        return true;
      },
      () => false,
      "sv-property-editor",
      "Add Question Logic",
      "popup"
    );
  }

  renderEachConditions(str, title): JSX.Element {
    if(!str) return null;
    const isNewSchema = str.includes("evaluate(");
    // @ts-ignore
    if(isNewSchema && window.renderExpressionTreeToHTML) {
      const jsonString = str.match(/evaluate\((.*)\)/)[1];
      const parsedExpression = JSON.parse(JSON.parse(jsonString));
      // @ts-ignore
      const htmlStr = window.renderExpressionTreeToHTML(str, null, 10);
      return (
        <div key={`${title}_container_${this.props.question.id}`} className="condition-container">
          <div className="condition-container-actions">
            <h1 className="condition-type">{title}</h1>
            <DotMenu
              callbackOnEdit={() => this.callbackOnEdit()}
              callbackOnDelete={() => this.callbackOnDelete()} />
          </div>
          <div dangerouslySetInnerHTML={{ __html: htmlStr }} />
        </div>
      );
    }
    const survey = this.props.question.getSurvey();
    const regex = /(=|<>|>|<|>=|<=|notempty|anyof|allof|empty|contains)/;
    function parseStr(str) {
      const parts = str.split(/\b\s*(and|or)\s*\b/g);
      for (let i = 0; i < parts.length - 1; i++) {
        if (parts[i].trim().endsWith("and") || parts[i].trim().endsWith("or")) {
          parts[i] += parts.splice(i + 1, 1)[0];
        }
      }

      return parts;
    }
    function getQuestion(str) {
      let match = str.match(/\{(.*?)\}/); // Regular expression to match content inside curly braces
      let questionName = match ? match[1] : null; // Extracting the content inside the braces if match is found
      let question = survey.getQuestionByName(questionName);
      let name = "";
      if(!question && questionName) {
        //@ts-ignore
        const allQ = survey.getAllQuestions().map(i => i.name);
        const originQuestionName = allQ.find(i => questionName.includes(i));
        // console.log('-allQ',allQ);
        // console.log('questionName', originQuestionName);
        question = survey.getQuestionByName(originQuestionName);
        name = questionName.split(`${originQuestionName}.`)[1];
      }
      // console.log('---question', question);
      //@ts-ignore
      const num = question?.no?.replace(".", "");
      //@ts-ignore
      return `Q${num} ${question?.title || questionName} ${name ? `(${name})` : ""}`;
    }
    function getTheLastPart(str) {
      const symbolsRegex = /['"\[\]]/g;
      let operator = str.match(regex) ? str.match(regex)[0] : null; // Extracting the operator from the string
      if(!operator) return "";
      return str.split(operator)[1].trim().replace(symbolsRegex, "");
    }
    const operatorText = {
      "=": "Equals",
      "<>": "Does not equal",
      ">": "Greater than",
      "<": "Less than",
      ">=": "Greater than or equal to",
      "<=": "Less than or equal to",
      "notempty": "Not Empty",
      "anyof": "Any Of",
      "allof": "All of",
      "empty": "Empty",
      "contains": "Contains"
    };
    function getOperator(str) {
      let operatorFound = null;
      const sortedOperators = Object.keys(operatorText).sort((a, b) => b.length - a.length);
      sortedOperators.some(operator => {
        if (str.includes(operator)) {
          operatorFound = operator;
          return true;
        }
      });

      return operatorText[operatorFound] || "null";
    }
    function getTextBeforeBrace(text) {
      const match = text.match(/^(.*?)\s*{/);
      return match ? match[1] : "";
    }
    if(typeof str === "string") {
      const arrCases = parseStr(str);
      const textForCases = arrCases.reduce((acc, str, index) => {
        let obj = {
          operator: !index ? "IF" : getTextBeforeBrace(str).includes("or") ? "OR" : "AND",
          question: getQuestion(str),
          operatorForCase: getOperator(str),
          lastPart: getTheLastPart(str),
        };
        acc.push(obj);
        return acc;
      }, []);
      return (
        <div key={`${title}_container_${this.props.question.id}`} className="condition-container">
          <div className="condition-container-actions">
            <h1 className="condition-type">{title}</h1>
            <DotMenu
              callbackOnEdit={() => this.callbackOnEdit()}
              callbackOnDelete={() => this.callbackOnDelete()} />
          </div>
          {textForCases.map(({ operator, question, operatorForCase, lastPart }) => (
            <p key={`cases_${operator}-${question}`} className="condition-case">
              <span className='condition-info-operator'>{operator}</span>
              <span className="condition-question-name">{question}</span>
              <span className='condition-info'> {operatorForCase}</span>
              <span className='condition-lastPart'> {lastPart}</span>
            </p>
          ))}
        </div>
      );
    }
    return <div />;
  }

  renderConditions(): JSX.Element {
    let conditions = this.props.question.getConditionJson ? this.props.question.getConditionJson() : {};
    const conditionName = {
      enableIf: "Enable If",
      resetValueIf: "Reset Value If",
      setValueIf: "Set Value If",
      requiredIf: "Required If",
      visibleIf: "Visible If"
    };
    if(!conditions) {
      conditions = Object.keys(conditionName).reduce((acc, key) => {
        if(this.props.question[key]) acc[key] = this.props.question[key];
        return acc;
      }, {});
    }
    return (
      <div className="condition-root-container">
        { !!conditions && Object.keys(conditionName).map(key => this.renderEachConditions(conditions[key], conditionName[key]))}
      </div>
    );
  }
}

ReactElementFactory.Instance.registerElement(
  "svc-question",
  (props: QuestionAdornerComponentProps) => {
    return React.createElement(QuestionAdornerComponent, props);
  }
);
